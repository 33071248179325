export const dataLayerPush = (varName: string, varValue: unknown) => {
  if (typeof window !== 'undefined' && varValue) {
    window.dataLayer = window.dataLayer || [];

    const data: Record<string, unknown> = {};

    data[varName] = varValue;

    if (window.dataLayer.indexOf(data) < 0) {
      window.dataLayer.push(data);
    }
  }
};
