import { Link } from '@remix-run/react';
import { useBreakpoint } from '@hipagesgroup/toolbox';

const DESKTOP_FOOTER_LINKS = [
  {
    title: 'Get a quote',
    href: '/',
  },
  {
    title: 'About us',
    href: '/about',
  },
  {
    title: 'Contact us',
    href: '/contact',
  },
  {
    title: 'Help',
    href: '/help',
  },
  {
    title: 'For Tradies',
    href: 'https://hipages.com.au/registration',
  },
  {
    title: 'Privacy Policy',
    href: '/privacy',
  },
  {
    title: 'Terms of use',
    href: '/termsofuse',
  },
];

const MOBILE_FOOTER_LINKS = DESKTOP_FOOTER_LINKS.filter((link) => {
  return (
    link.title === 'For Tradies' || link.title === 'Privacy Policy' || link.title === 'Terms of use'
  );
}).sort((a, b) => {
  return a.title.localeCompare(b.title);
});

const Footer = () => {
  const afterClassName =
    'flex items-center gap-ml relative after:content-[""] after:bg-content-muted after:w-[1px] after:h-[12px]';
  const isDesktop = useBreakpoint('sm');
  const candidateLinks = isDesktop ? DESKTOP_FOOTER_LINKS : MOBILE_FOOTER_LINKS;

  return (
    <footer className="py-lg bg-surface text-content-muted">
      <nav className="max-w-[1008px] mx-auto px-md">
        <ul className="flex flex-wrap items-center justify-center gap-ml text-body">
          {candidateLinks.map(({ title, href }, index) => (
            <li
              className={index === candidateLinks.length - 1 ? undefined : afterClassName}
              key={`footer-item-${href}`}
            >
              <Link to={href} className="hover:text-content-link">
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;
