import { useEffect } from 'react';
import isMatch from 'lodash/isMatch';
import { dataLayerPush } from '@hipagesgroup/utils';

interface SeoProps {
  siteSection: string;
  siteSubsection?: string;
  title?: string;
  h1?: string;
  h2?: string;
  templateId?: string;
  seoName?: string;
  targetKeyword?: string;
  canonicalLink?: string;
  metaDescription?: string;
  robots?: string;
}

export const WHITELIST_FORM_EVENT_TRACKING = 'whitelist-from-event-tracking';

const pushDataLayerVariables = (dataLayerVariables: Record<string, unknown>) => {
  for (const [key, value] of Object.entries(dataLayerVariables)) {
    dataLayerPush(key, value);
  }
};

export function addSchema(
  schema: string,
  data: Record<string, unknown>,
  dataIdentifiers?: Record<string, unknown>
) {
  removeSchema(schema, dataIdentifiers);

  window?.pageContexts?.push({
    schema,
    data,
  });
}

export function removeSchema(schema: string, dataIdentifiers?: Record<string, unknown>) {
  window.pageContexts = window.pageContexts || [];

  const contextIndex = window.pageContexts.findIndex((context) =>
    dataIdentifiers
      ? context.schema === schema && isMatch(context.data, dataIdentifiers)
      : context.schema === schema
  );

  if (contextIndex > -1) {
    window.pageContexts.splice(contextIndex, 1);
  }
}

export function useSchema(
  schema: string,
  config: {
    data: Record<string, unknown>;
    dataIdentifiers?: Record<string, unknown>;
    shouldAddSchema?: boolean;
  },
  dependencies: string[]
) {
  const { data, dataIdentifiers } = config;
  const shouldAddSchema = config.shouldAddSchema ?? true;

  return useEffect(() => {
    if (!data || !shouldAddSchema) return;

    addSchema(schema, data, dataIdentifiers);

    return () => removeSchema(schema, dataIdentifiers);
  }, dependencies); /* eslint-disable-line react-hooks/exhaustive-deps */ // TODO - fix this
}

// Hook to set up seo metrics by pushing into datalayer and setting up the schema as well
export function useSetupSeoMetrics(seoProps?: SeoProps) {
  const { templateId, ...rest } = seoProps || {};
  const {
    siteSection,
    siteSubsection,
    title,
    h1,
    h2,
    seoName,
    targetKeyword,
    canonicalLink,
    metaDescription,
    robots,
  } = seoProps || {};

  // Set up datalayer variables
  useEffect(() => {
    if (Object.keys(rest).length > 0) {
      pushDataLayerVariables(rest);
    }
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */ // TODO - fix this

  const schemaData = {
    site_section: siteSection,
    site_subsection: siteSubsection,
    title: title,
    h1,
    h2,
    template_id: templateId,
    seo_name: seoName,
    target_keyword: targetKeyword,
    canonical_link: canonicalLink,
    meta_description: metaDescription,
    robots: robots,
  };

  // Sanitize schema data to remove empty and undefined strings
  Object.keys(schemaData).forEach(
    (key) =>
      !schemaData[key as keyof typeof schemaData] &&
      delete schemaData[key as keyof typeof schemaData]
  );

  // Set up seo schema on page load
  useSchema(
    'iglu:au.com.hipagesgroup.hip/seo/jsonschema/1-0-0',
    {
      data: schemaData,
    },
    []
  );
}
