import { useState, useCallback, useMemo } from 'react';
import { InlineSearch } from '@hipagesgroup/toolbox';
import { LocationSuggestion, useLocationSelect } from '@hipagesgroup/get-quotes';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';

interface LocationSelectProps {
  placeholder?: string;
  userTextInput: string;
  setUserTextInput: (_: string) => void;
  onValueSelect: (location?: string) => void;
  onFocus?: () => void;
}

const DEFAULT_INPUT_PLACEHOLDER = 'e.g. 2000';

export const GetQuoteLocationSelect = ({
  placeholder = DEFAULT_INPUT_PLACEHOLDER,
  onFocus,
  userTextInput,
  setUserTextInput,
  onValueSelect,
}: LocationSelectProps) => {
  const [filterText, setFilterText] = useState<string>('');
  const { locations, isFetching } = useLocationSelect(filterText);

  const locationOptions = useMemo(() => {
    return uniqBy(locations, 'label');
  }, [locations]);

  const getSelectedLocation = useCallback(
    (e: string): LocationSuggestion | null => {
      return locationOptions.find((location) => location.id === e) || null;
    },
    [locationOptions]
  );

  const debouncedSetFilterText = useMemo(
    () => debounce(setFilterText, 200, { leading: true }),
    [setFilterText]
  );

  const onValueChange = useCallback(
    (newValue: string | null) => {
      const locationOption = getSelectedLocation(newValue || '');
      onValueSelect(locationOption?.id);
    },
    [getSelectedLocation, onValueSelect]
  );

  const onInputValueChange = useCallback(
    (newInputValue: string | null) => {
      newInputValue = (newInputValue || '')?.replace(/\s\s+/g, ' ');

      // Update both userInputText and the debounced version
      // This way, the input text changes with every keystroke, but anything that depends on the debounced text only fires in a throttled manner
      setUserTextInput(newInputValue);
      debouncedSetFilterText(newInputValue);
    },
    [setUserTextInput, debouncedSetFilterText]
  );

  return (
    <InlineSearch
      loading={isFetching}
      allowFreeText={false}
      inputValue={userTextInput}
      onValueChange={onValueChange}
      onInputValueChange={onInputValueChange}
    >
      <InlineSearch.Input placeholder={placeholder} autoFocus onFocus={onFocus} />

      <InlineSearch.Content>
        {locationOptions.map(({ id, label }) => (
          <InlineSearch.Item key={id} value={id} label={label} className="hover:cursor-pointer" />
        ))}
        <InlineSearch.Loading>Loading...</InlineSearch.Loading>
        <InlineSearch.Empty>
          No results for <span className="text-body-emphasis">"{userTextInput}"</span>
        </InlineSearch.Empty>
      </InlineSearch.Content>
    </InlineSearch>
  );
};
